import { createApp } from 'vue'
import App from './App.vue'

// Configure Amplify
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

// Configure Fontawesome icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStrava } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faStrava);

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
